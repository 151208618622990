import { default as carrierGGgblFVffkMeta } from "/opt/buildhome/repo/pages/carrier.vue?macro=true";
import { default as commander5SHQ1BB84TMeta } from "/opt/buildhome/repo/pages/commander.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as managementxLXEiu6AUaMeta } from "/opt/buildhome/repo/pages/management.vue?macro=true";
export default [
  {
    name: carrierGGgblFVffkMeta?.name ?? "carrier",
    path: carrierGGgblFVffkMeta?.path ?? "/carrier",
    meta: carrierGGgblFVffkMeta || {},
    alias: carrierGGgblFVffkMeta?.alias || [],
    redirect: carrierGGgblFVffkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/carrier.vue").then(m => m.default || m)
  },
  {
    name: commander5SHQ1BB84TMeta?.name ?? "commander",
    path: commander5SHQ1BB84TMeta?.path ?? "/commander",
    meta: commander5SHQ1BB84TMeta || {},
    alias: commander5SHQ1BB84TMeta?.alias || [],
    redirect: commander5SHQ1BB84TMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/commander.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: managementxLXEiu6AUaMeta?.name ?? "management",
    path: managementxLXEiu6AUaMeta?.path ?? "/management",
    meta: managementxLXEiu6AUaMeta || {},
    alias: managementxLXEiu6AUaMeta?.alias || [],
    redirect: managementxLXEiu6AUaMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/management.vue").then(m => m.default || m)
  }
]